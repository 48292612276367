import React, { useState, useEffect } from 'react';
import axios from 'axios';
import jsonpAdapter from 'axios-jsonp';
import dayjs from 'dayjs';

import { Text, Box, Flex, Image, jsx, Button } from 'theme-ui';
import Layout from '../components/Layout';

const Events = () => {
  const [events, setEvents] = useState([]);
  const ID = 'bzn-PKzc8iPtRTSo0pG0hel46g';
  useEffect(() => {
    axios({
      url: `https://data.prod.gonation.com/profile/events?profile_id=${ID}`,
      adapter: jsonpAdapter,
    }).then(({ data }) => {
      setEvents(e => [...e, ...data.events]);
    });

    axios({
      url: `https://data.prod.gonation.com/profile/recurringevents?profile_id=${ID}`,
      adapter: jsonpAdapter,
    }).then(({ data }) => {
      setEvents(e => [...e, ...data.events]);
    });
  }, []);

  const getEventDays = days => {
    if (days.length === 7) {
      return 'Every Day';
    }
    if (
      days.length === 5 &&
      !days.includes('saturday') &&
      !days.includes('sunday')
    ) {
      return 'Every Weekend';
    }
    if (
      days.length === 2 &&
      days.includes('saturday') &&
      days.includes('sunday')
    ) {
      return 'Every Weekend';
    }
    return `Every ${days.map(day => day).join(', ')}`;
  };

  const addHTTP = url => {
    const prefix = 'https://';
    if (url.substr(0, prefix.length) !== prefix) {
      return prefix + url;
    }
    return url;
  };

  const renderCTA = event => {
    const buttonNames = Object.keys(event.ctas).filter(
      btn => btn !== 'cta1' && btn !== 'cta2'
    );
    return buttonNames.map(btn => {
      return (
        <Text
          as='a'
          sx={{
            padding: '16px 64px',
            border: ['none', '2px solid'],
            borderColor: 'primary',
            transition: `all .25s`,
            color: ['white', 'primary'],
            '&:hover': {
              boxShadow: `2px 2px 8px rgba(0,0,0,.2)`,
              transition: `all .25s`,
            },
          }}
          target='_blank'
          rel='noopenner'
          href={addHTTP(event.ctas[btn])}>
          {btn}
        </Text>
      );
    });
  };

  const displayEventDate = evt => {
    const hardcodedTimes = {
      '2020-12-11T17:00:00.000Z': '11 - 24',
      '2020-12-12T18:00:00.000Z': 12,
    };
    if (evt.eventDays) {
      return getEventDays(Object.keys(evt.eventDays));
    }
    if (evt.starts) {
      const hardcodeLookup = hardcodedTimes[evt.starts];
      return (
        <>
          <Flex sx={{ alignItems: 'center' }}>
            <Box sx={{ paddingRight: 2 }}>
              <Text as='h3' sx={{ fontSize: [7, 8] }}>
                {hardcodeLookup
                  ? hardcodeLookup
                  : dayjs(evt.starts).format('D')}
              </Text>
            </Box>
            <Box>
              <Text
                as='p'
                sx={{ fontSize: [1, '26px'], fontWeight: '500', mb: 2 }}>
                {dayjs(evt.starts).format('MMMM')}
              </Text>
              {/* <Text as='p' sx={{ fontSize: [1, 2], fontWeight: '500' }}>
                {dayjs(evt.starts).format('dddd')}
              </Text> */}
            </Box>
          </Flex>
        </>
      );
    }
  };
  return (
    <Layout pageTitle='events'>
      <Box as='section' sx={{ paddingTop: [6, 'unset'] }}>
        {events.length
          ? events.map((evt, idx) => (
              <Flex sx={{ flexWrap: 'wrap' }}>
                <Flex
                  sx={{
                    flex: ['1 1 100%', '1 1 100%', '1 1 50%'],
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: [3, 3, 'unset'],
                    bg: idx % 2 !== 0 ? 'black' : '',
                    color: idx % 2 !== 0 ? 'white!important' : '',
                  }}>
                  <Box sx={{ paddingX: [2, 5, 6] }}>
                    <Text as='h3' sx={{ textTransform: 'uppercase' }}>
                      {displayEventDate(evt)}
                    </Text>
                    <Text as='h4' sx={{ fontSize: [3, 4, 5], mb: 3 }}>
                      {evt.name}
                    </Text>
                    <Text as='p' sx={{ fontSize: [2], lineHeight: 1.75 }}>
                      {evt.description.substring(0, 100)}...
                    </Text>
                    <Box sx={{ mt: 5 }}>{renderCTA(evt)}</Box>
                    <Text></Text>

                    <Box sx={{ marginTop: 4 }}>
                      <Text
                        as='a'
                        href={`https://gonation.com/place/135-washington/events/${evt._id}/info`}
                        target='_blank'
                        rel='noopener noreferrer'
                        sx={{ cursor: 'pointer' }}>
                        <Button
                          sx={{
                            padding: `16px 64px`,
                            fontSize: 2,
                            border: '2px solid',
                            borderColor:
                              idx % 2 !== 0 ? 'white!important' : 'text',
                            bg: 'transparent',
                            color: 'text',
                            textTransform: 'uppercase',
                            fontWeight: '400',
                            cursor: 'pointer',
                            borderRadius: 0,
                            color: idx % 2 !== 0 ? 'white!important' : 'text',
                          }}>
                          Read More
                        </Button>
                      </Text>
                    </Box>
                  </Box>
                </Flex>
                <Box
                  sx={{
                    flex: ['1 1 100%', '1 1 100%', '1 1 50%'],
                    order: idx % 2 === 0 ? ['', '', '1'] : ['', '', '-1'],
                  }}>
                  <Image src={evt.imageurl} />
                </Box>
              </Flex>
            ))
          : ''}
      </Box>
    </Layout>
  );
};

export default Events;
